import { getBackgroundCSS, getBorderCSS, getColorsCSS, getSeparatorCSS, getShadowCSS, getSpaceCSS, getTypoCSS } from '../../../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { alignment, width, background, padding, border, shadow, headerMargin, titleTypo, titleColor, descTypo, descColor, headerSep, listIconSize, listIconColors, listTextTypo, listTextColor } = attributes;

	const mainSl = `#ilbIconList-${clientId}`;
	const iconListSl = `${mainSl} .ilbIconList`;
	const headerSl = `${iconListSl} .header`;

	const iconSize = 30 < listIconSize ? listIconSize + listIconSize / 2 : listIconSize + listIconSize / 1.5;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS('', titleTypo)?.googleFontLink}
		${getTypoCSS('', descTypo)?.googleFontLink}
		${getTypoCSS('', listTextTypo)?.googleFontLink}
		${getTypoCSS(`${headerSl} .title`, titleTypo)?.styles}
		${getTypoCSS(`${headerSl} .description`, descTypo)?.styles}
		${getTypoCSS(`${iconListSl} ul.lists .text`, listTextTypo)?.styles}

		${mainSl}{
			text-align: ${alignment};
		}
		${iconListSl}{
			width: ${['0px', '0%', '0em'].includes(width) ? 'auto' : width};
			${getBackgroundCSS(background)}
			padding: ${getSpaceCSS(padding)};
			${getBorderCSS(border)}
			box-shadow: ${getShadowCSS(shadow)};
		}
		${headerSl}{
			margin: ${getSpaceCSS(headerMargin)};
		}
		${headerSl} .title{
			color: ${titleColor};
		}
		${headerSl} .description{
			color: ${descColor};
		}
		${headerSl} .separator{
			${getSeparatorCSS(headerSep)}
		}
		${iconListSl} ul.lists .icon{
			font-size: ${listIconSize}px;
			width: ${iconSize}px;
			height: ${iconSize}px;
			${getColorsCSS(listIconColors)}
		}
		${iconListSl} ul.lists .text{
			max-width: calc(100% - ${iconSize + 15}px);
			color: ${listTextColor};
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;